import React, { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Box, Flex, Text, Button, Image } from 'rebass';
import { IoMdLink, IoMdShare } from 'react-icons/io';
import { useDispatch } from 'react-redux';
import { setCheckmark } from 'ui/actions';
import { useHistory } from 'react-router-dom';
import QuestionBar from 'stories/tell-a-story/QuestionBar';
import Firefly from './assets/Firefly.png';
import { FaArrowRight } from 'react-icons/fa';
import { ShareSocial } from 'react-share-social'

const sendEmailToEmailAddress = (url) => {
  const title = 'Tell a story';

  const body = `${url}`;
  return `mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(
    body
  )}`;
};

function Span({ sx = {}, children, ...props }) {
  return (
    <Text
      sx={{
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '120%',
        letterSpacing: '-0.396px',
        ...sx,
      }}
      {...props}
    >
      {children}
    </Text>
  );
}

function UpArrow() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="61" height="16" viewBox="0 0 61 16" fill="none">
      <path
        d="M0.5 14.5C0.223858 14.5 0 14.7239 0 15C0 15.2761 0.223858 15.5 0.5 15.5V14.5ZM57.5 15V15.5H58V15H57.5ZM57.5 0.5L54.6132 5.5H60.3868L57.5 0.5ZM0.5 15.5H57.5V14.5H0.5V15.5ZM58 15V5H57V15H58Z"
        fill="black"
      />
    </svg>
  );
}

const style = {
  root: {
    maxWidth: 500,
    width: '95vw',
    borderRadius: 3,
    padding: "0px 20px",
    border: 0,
  },
  iconContainer: {
    paddingTop: '0px'
  },
  copyContainer: {
    color: '#13C283',
    border: '2px dashed #342E57',
    borderRadius: '8px',
    background: '#fff',
    width: '100%',
  },
  copyUrl: {
    padding: 2,
    background: '#fff',
    border: 0,
    fontWeight: 700,
    width: ['calc(100vw - 168px)', '100%'],
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    borderRadius: '8px',
    color: '#13C283',
  },
  copyIcon: {
    color: '#13C283',
  },
  title: {
    color: 'aquamarine',
    fontStyle: 'italic',
    color: '#333',
  }
};

function CopyLink({ memoirId, storyRequestData }) {
  const [supportShareApi, setSupportShareApi] = useState(false)
  const { replace } = useHistory()
  const dispatch = useDispatch()
  const publicVideoLink = storyRequestData?.['contributor-url'];

  const shareData = {
    title: "Check this out!",
    text: "This will be awesome",
    url: publicVideoLink,
  }

  useEffect(() => {
    if (!navigator.canShare) {
      setSupportShareApi(false)
    } else if (navigator.canShare(shareData)) {
      setSupportShareApi(true)
    }
  }, [])

  return (
    <Flex flexDirection="column"
      maxWidth={500}
      m="auto"
      width="100%"
      p={2}
      pb={7}
      sx={{ position: 'absolute' }}
      justifyContent="center"
      alignItems="center"
    >
      <Text
        textAlign="center"
        maxWidth="320px"
        sx={{
          fontSize: '20px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: '120%',
          letterSpacing: '-0.44px',
          color: 'purples.10',
        }}
      >
        Share the link below to get responses to the question:
      </Text>
      <QuestionBar question={storyRequestData?.question} showBorder={false} />
      {supportShareApi &&
        <Button
          height={60}
          padding="16px 20px"
          width="max-content"
          my={2}
          bg="#53F"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '200px'
          }}
          onClick={() => {
            navigator.share(shareData)
              .then(() => dispatch(setCheckmark({
                message: 'Link shared successfully',
                top: 100,
                height: '200px',
                width: '200px'
              })))
              .catch((error) => console.error('Error sharing content:', error));
          }}
        >
          Share with your friends and family
          <Box as={IoMdShare} color="white" pl={2} size={30} />
        </Button>
      }
      {supportShareApi ?
        <CopyToClipboard
          text={publicVideoLink}
          onCopy={() => {
            dispatch(setCheckmark({
              message: 'Link copied and is ready to paste into an email or text message',
              top: 100,
              height: '200px',
              width: '200px'
            }));
          }}

        >
          <Flex
            sx={{
              border: '2px dashed #342E57',
              borderRadius: '8px',
              background: '#fff',
              width: '100%'
            }}
          >
            <Flex
              alignItems="center"
              sx={{
                p: 2,
                background: '#fff',
                border: 0,
                fontWeight: 700,
                width: ['calc(100vw - 168px)', '100%'],
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                borderRadius: '8px',
                color: '#13C283',
              }}
            >
              {publicVideoLink}
            </Flex>
            <Button
              bg={'#fff'}
              sx={{ border: 0 }}
            >
              <Text as={IoMdLink} color="#13C283" size={24} />
            </Button>
          </Flex>
        </CopyToClipboard>
        : <ShareSocial
          title="Share with your friends and family"
          url={publicVideoLink}
          socialTypes={['facebook', 'email', 'telegram', 'whatsapp']}
          style={style}
          onSocialButtonClicked={(e) => {
            if (e === 'email') {
              window.open(
                sendEmailToEmailAddress(publicVideoLink),
                '_blank'
              )
            }
          }}
        />}
      <Flex mt={1} alignItems="left" width="100%">
        <Span
          mr={1}
          sx={{
            fontSize: '18px',
            fontStyle: 'italic',
            letterSpacing: '-0.396px',
            lineHeight: '120%',
            fontWeight: 500,
          }}
        >
          Or tap to copy link
        </Span>
        <UpArrow />
      </Flex>
      <Box my={3} textAlign="center">
        <Image src={Firefly} maxWidth={380} />
        <Text
          mt="20px"
          width={320}
          mx="auto"
          sx={{
            color: 'rgba(0, 0, 0, 0.70)',
            textAlign: 'center',
            fontFamily: "DM Sans",
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '20px',
          }}
        >
          When they respond, their stories will appear on your dashboard.
        </Text>
      </Box>

      <Button
        height={60}
        padding="16px 20px"
        width={300}
        mt={5}
        bg="#53F"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '200px'
        }}
        onClick={() => {
          replace(`/memoirs/${memoirId}`)
        }}
      >
        Return to home
        <Box as={FaArrowRight} color="white" pl={2} size={30} />
      </Button>

    </Flex >
  );
}

export default CopyLink;
