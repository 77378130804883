import React, { useState, useEffect } from 'react';
import { Box, Heading, Flex } from 'rebass';
import { v4 } from 'uuid';
// import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
// import { VoiceRecorder } from "react-voice-recorder-player";
import Modal from 'app/modal/v3';
import ActionItems from './ActionItems';
import { FaMicrophone } from 'react-icons/fa';
import AudioPlayer from './AudioPlayer';
import RecordButton from 'chats/RecordButton';
import { ControlButtons } from 'chats/VideoPreview';
import { Label } from 'story-media/new/styled';
import ButtonLabelWrap from 'story-media/new/button-label-wrap';
import ReactAudioPlayer from './ReactAudioPlayer';
import MicRecorder from 'mic-recorder-to-mp3';
import { checkIsIpadUser, useCheckIsIosUser, useIsSafariUser } from 'story-media/hooks';

function RecordAudio({ onSubmitMedia, question, isSelected, onClick = () => { }, label = "Respond with audio", justIcon = false }) {
    const [startRecording, setStartRecording] = useState(false);
    const [isRecording, setIsRecording] = useState(null);
    const [recorder, setRecorder] = useState(null);

    const [file, setFile] = useState(null);
    const [url, setUrl] = useState(null);
    const [isDisabled, setIsMicrophoneDisabled] = useState(false);

    const [isPlaying, setIsPlaying] = useState(false)

    const isSafariUser = useIsSafariUser();
    const iOSUser = useCheckIsIosUser()
    const isIpadUser = checkIsIpadUser();
    const isIOSUser = iOSUser || isIpadUser;

    useEffect(() => {
        const recorder = new MicRecorder({
            bitRate: 128
        });
        setRecorder(recorder)
    }, [])
    // const recorderControls = useAudioRecorder()
    async function startAudioRecording() {
        await recorder.start()
    }

    function stopAudioRecording() {
        recorder.stop().getMp3().then(([buffer, blob]) => {
            const blobURL = URL.createObjectURL(blob);
            blob.name = `${v4()}.${blob.type.split('/')[1]}`;
            setFile(blob)
            setUrl(blobURL)
        })
    }

    useEffect(() => {
        if (isSafariUser || isIOSUser) {
            if (isRecording === true)
                startAudioRecording()
            else if (isRecording === false)
                stopAudioRecording()
        }
    }, [isRecording, isSafariUser, isIOSUser])

    const handleClick = async () => {
        setStartRecording(true)
        onClick()
        return
    };

    useEffect(() => {
        if (startRecording)
            navigator.mediaDevices
                .getUserMedia({ audio: true })
                .then(function (stream) {
                    console.log('Microphone access is allowed. ', stream);
                    stream.getTracks().forEach((track) => {
                        track.stop();
                    });
                    stream.getAudioTracks().forEach((track) => {
                        track.stop();
                    });
                })
                .catch(function (err) {
                    if (err.name === 'NotAllowedError' || err.name === 'PermissionDeniedError') {
                        console.log('Microphone access is denied.', err);
                        setIsMicrophoneDisabled(true);
                    } else {
                        console.error('Error accessing microphone:', err);
                    }
                });

    }, [startRecording])

    const onClose = () => {
        setFile(null)
        setUrl(null)
        setStartRecording(false)
        setIsRecording(false)
    }

    const handleChange = (e) => {
        return onSubmitMedia(e.target.files, true);
    }

    const onStop = ({ blob, url, type }) => {
        if (!isSafariUser && !isIOSUser) {
            try {
                blob.name = `${v4()}.${type.split('/')[1]}`;
                setFile(blob)
                setUrl(url)
            }
            catch (err) {
                console.log(err)
            }
        }
    };


    return <>
        {startRecording && <Modal
            close={{ onClose }}
            maxWidth={800}
            title={question}
            closeOnOutsideClick={false}
            aboveTooltip
        >
            {isDisabled ? <Box className="disabledVideoContentContainer" textAlign="left" m={4}>
                <Heading variant="headings.h1" color="#ED2300" fontSize={20}>
                    To record an audio, your browser needs access to your microphone.
                    <br />
                    <br />
                    Please refresh this page and grant permission. If that doesn&apos;t work, go to your
                    phone&apos;s browser settings to provide permission.
                </Heading>
            </Box> : <Flex flexDirection="column" alignItems="center">
                {url &&
                    <AudioPlayer
                        src={url}
                        isPlaying={isPlaying}
                        setIsPlaying={setIsPlaying}
                    />
                }
                {!isSafariUser && !isIOSUser && !url && <ReactAudioPlayer
                    isRecording={isRecording}
                    onStop={onStop}
                />}

                {url ?
                    <ControlButtons
                        isPlaying={isPlaying}
                        handlePlayPause={() => setIsPlaying(!isPlaying)}
                        onReject={() => {
                            setIsPlaying(false)
                            setIsRecording(false)
                            setFile(null)
                            setUrl(null)
                        }}
                        rejectLabel="Re-record"
                        onAccept={() => {
                            const newFile = new File([file], file.name, { type: file.type, lastModified: Date.now() });
                            handleChange({ target: { files: [newFile] } })
                            onClose()
                        }}
                    />
                    : <RecordButton
                        isRecording={isRecording}
                        onClick={() => setIsRecording(!isRecording)}
                    />}
            </Flex>}
        </Modal>
        }

        {justIcon ? <Box onClick={handleClick} >
            <ButtonLabelWrap label={label} >
                <Label>
                    <FaMicrophone />
                </Label>
            </ButtonLabelWrap>
        </Box>
            : <ActionItems
                onClick={handleClick}
                isSelected={isSelected}
                nextIcon={isSelected}
                icon={FaMicrophone}
                mt={2}
                label={label}
            />}
    </>
}

export default RecordAudio;